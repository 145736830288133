export default interface ShiftTemplateType {
    id: number
    name: string
    validFrom: string
    location: number
    weekCycle: number
    occupationTemplate: number
    isPublished: boolean
}

export enum ShiftTemplateInitMode {
    EMPTY = "empty",
    GENERATE = "generate",
    COPY = "copy",
}

export interface CreateShiftTemplateType {
    name: string
    validFrom: string | null
    initializationMode: ShiftTemplateInitMode
    location: number
    sourceTemplate: number | null
    weekCycle: number
    occupationTemplate: number | null
    users: number[]
    roles: number[]
}

export interface UpdateShiftTemplateType {
    id: number
    name?: string
    validFrom?: string | null
    weekCycle?: number
    occupationTemplate?: number | null
    isPublished?: boolean
}

export interface PublishShiftTemplateType {
    id: number
    isPublished: boolean
}
