import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { AxiosResponse } from "axios"
import { FC, useCallback, useEffect, useMemo, useState } from "react"
import { Button, Form } from "react-bootstrap"
import DatePicker from "react-datepicker"
import { SubmitHandler, useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import InlineFormMultiSelect from "../../components/form/InlineFormMultiSelect"
import InfoPopover from "../../components/InfoPopover"
import { Breadcrumb } from "../../components/page/Breadcrumb"
import RegularPage from "../../components/page/RegularPage"
import DatePickerHeader from "../../components/table/DatePickerHeader"
import { useTabs } from "../../contexts/TabsContext"
import { useRolesForSelectedLocation, useSelectedLocation, useUsersForSelectedLocation } from "../../contexts/UserSettingsContext"
import { ROLE_TYPE_REGULAR } from "../../helpers/Constants"
import { dateFromDjango, dateToDjango } from "../../helpers/DaysHelper"
import { setOptionalError } from "../../helpers/FormHelper"
import { getRoleOptionsForType } from "../../helpers/RolesHelper"
import { getNonExternalEmployableUsers, getUserOptions } from "../../helpers/UsersHelper"
import { loadOccupationTemplatesForLocation } from "../../services/OccupationTemplate"
import { CreatedShiftTemplateResponse, createShiftTemplate, deleteShiftTemplate, loadShiftTemplatesForLocation, updateShiftTemplate } from "../../services/ShiftTemplate"
import ShiftTemplateType, { ShiftTemplateInitMode } from "../../types/ShiftTemplateType"

const initializationModeOptions = [
    { id: ShiftTemplateInitMode.EMPTY, name: "Leeg" },
    { id: ShiftTemplateInitMode.GENERATE, name: "Genereer automatisch" },
    { id: ShiftTemplateInitMode.COPY, name: "Kopieer ander basisrooster" },
]

interface Props {
    mode: "Create" | "Update"
    shiftTemplate?: ShiftTemplateType
}

interface Inputs {
    name: string
    validFrom: Date | null
    initializationMode: ShiftTemplateInitMode
    sourceTemplate: string
    occupationTemplate: string
    weekCycle: number
    isPublished: boolean
    users: string[]
    roles: string[]
}

const EditShiftTemplateDetailsPage: FC<Props> = ({ mode, shiftTemplate }) => {
    const navigate = useNavigate()
    const location = useSelectedLocation()
    const allUsers = useUsersForSelectedLocation()
    const allRoles = useRolesForSelectedLocation()
    const { setActiveTab } = useTabs()
    const {
        register,
        setError,
        setValue,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm<Inputs>({
        defaultValues: {
            validFrom: new Date(),
            users: getNonExternalEmployableUsers(allUsers, new Date()).map((u) => u.id.toString()),
            roles: getRoleOptionsForType(allRoles, ROLE_TYPE_REGULAR).map((r) => r.id.toString()),
        },
    })

    const [deletionWarningVisible, setDeletionWarningVisible] = useState(false)

    const { data: shiftTemplates } = useQuery(["ShiftTemplatesForLocation", location.id], loadShiftTemplatesForLocation(location.id), { enabled: mode === "Create", initialData: [] })
    const { data: occupationTemplates } = useQuery(["OccupationTemplatesForLocation", location.id], loadOccupationTemplatesForLocation(location.id), { initialData: [] })

    useEffect(() => {
        if (shiftTemplate && shiftTemplate.location !== location.id) {
            navigate("/basisroosters")
            return
        }
        setValue("name", shiftTemplate?.name || "")
        setValue("validFrom", shiftTemplate ? dateFromDjango(shiftTemplate?.validFrom) : new Date())
        setValue("initializationMode", ShiftTemplateInitMode.EMPTY)
        setValue("sourceTemplate", "")
        setValue("occupationTemplate", shiftTemplate?.occupationTemplate?.toString() || "")
        setValue("weekCycle", shiftTemplate?.weekCycle || 1)
        setValue("isPublished", shiftTemplate?.isPublished || false)
    }, [shiftTemplate])

    const onSuccessCreate = useCallback(
        (response: AxiosResponse<CreatedShiftTemplateResponse>) => {
            const newId = response.data.template.id
            const task = response.data.task
            if (task) {
                navigate(`/basisroosters/${newId}/genereer/${task.id}`)
            } else {
                navigate(`/basisroosters/${newId}`)
            }
        },
        [navigate]
    )

    const onSuccessUpdate = useCallback(() => {
        navigate(`/basisroosters/${shiftTemplate!.id}`)
    }, [navigate, shiftTemplate])

    const onSuccessDelete = useCallback(() => {
        navigate("/basisroosters/")
    }, [navigate])

    const onFailure = useCallback(
        (error: any) => {
            const data = error.response && error.response.data ? error.response.data : {}
            setOptionalError(setError, "name", data.name)
            setOptionalError(setError, "validFrom", data.validFrom)
            setOptionalError(setError, "initializationMode", data.initializationMode)
            setOptionalError(setError, "sourceTemplate", data.sourceTemplate)
            setOptionalError(setError, "occupationTemplate", data.occupationTemplate)
            setOptionalError(setError, "weekCycle", data.weekCycle)
            setOptionalError(setError, "isPublished", data.isPublished)
            setOptionalError(setError, "root", data.nonFieldErrors)
        },
        [setError]
    )

    const onSubmit: SubmitHandler<Inputs> = useCallback(
        ({ name, validFrom, initializationMode, sourceTemplate, occupationTemplate, weekCycle, users, roles, isPublished }) => {
            if (mode === "Create") {
                createShiftTemplate({
                    name,
                    validFrom: validFrom ? dateToDjango(validFrom) : null,
                    initializationMode: initializationMode,
                    location: location.id,
                    sourceTemplate: sourceTemplate ? parseInt(sourceTemplate) : null,
                    occupationTemplate: occupationTemplate ? parseInt(occupationTemplate) : null,
                    weekCycle,
                    users: users.map((u) => parseInt(u)),
                    roles: roles.map((r) => parseInt(r)),
                })
                    .then(onSuccessCreate)
                    .catch(onFailure)
            } else if (mode === "Update") {
                updateShiftTemplate({
                    ...shiftTemplate!,
                    name,
                    validFrom: validFrom ? dateToDjango(validFrom) : null,
                    occupationTemplate: occupationTemplate ? parseInt(occupationTemplate) : null,
                    weekCycle,
                    isPublished,
                })
                    .then(onSuccessUpdate)
                    .catch(onFailure)
            }
        },
        [location, onSuccessCreate, onSuccessUpdate, onFailure]
    )

    const onDeleteShiftTemplate = useCallback(() => {
        if (!deletionWarningVisible) {
            setDeletionWarningVisible(true)
            return
        }

        deleteShiftTemplate(shiftTemplate!.id).then(onSuccessDelete).catch(onFailure)
    }, [deletionWarningVisible, setDeletionWarningVisible, shiftTemplate, onSuccessDelete, onFailure])

    useEffect(() => setActiveTab("ShiftTemplates"), [setActiveTab])

    const pageTitle = useMemo(() => (mode === "Update" ? shiftTemplate?.name : "Nieuw basisrooster"), [mode, shiftTemplate])

    const breadcrumbs: Breadcrumb[] = useMemo(() => {
        const breadcrumbs: Breadcrumb[] = []
        breadcrumbs.push({ title: "Basisroosters", link: "/basisroosters" })
        if (mode === "Create") {
            breadcrumbs.push({ title: "Nieuw" })
        } else if (mode === "Update") {
            breadcrumbs.push({ title: shiftTemplate?.name ?? "", link: `/basisroosters/${shiftTemplate?.id}` })
            breadcrumbs.push({ title: "Details" })
        }
        return breadcrumbs
    }, [mode, shiftTemplate])

    const validFrom = watch("validFrom")
    const validFromIsError = useMemo(() => !!errors.validFrom, [errors])
    const setValidFrom = useCallback(
        (newValue: Date | null) => {
            setValue("validFrom", newValue)
            if (newValue) {
                setValue(
                    "users",
                    getNonExternalEmployableUsers(allUsers, newValue).map((u) => u.id.toString())
                )
            }
        },
        [setValue, allUsers]
    )

    const initializationMode = watch("initializationMode")

    const users = watch("users")
    const setUsers = useCallback((newValue: string[]) => setValue("users", newValue), [setValue])
    const userOptions = useMemo(() => {
        return getUserOptions(getNonExternalEmployableUsers(allUsers, validFrom ?? new Date()))
    }, [allUsers, validFrom])

    const roles = watch("roles")
    const setRoles = useCallback((newValue: string[]) => setValue("roles", newValue), [setValue])
    const roleOptions = useMemo(() => (allRoles ? getRoleOptionsForType(allRoles, ROLE_TYPE_REGULAR) : []), [allRoles])

    return (
        <RegularPage id="EditShiftTemplateDetails" breadCrumbs={breadcrumbs}>
            <div className="mb-4">
                <h1 className="h2">{pageTitle}</h1>
            </div>
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                    <Form.Label>Naam</Form.Label>
                    <Form.Control type="text" {...register("name")} isInvalid={!!errors.name} />
                    <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Startdatum</Form.Label>
                    <InfoPopover id="validFrom" body="De datum waarin het rooster van start gaat. Op de roosterpagina zie je pas het nieuwe rooster vanaf deze datum." />
                    <div className="d-block">
                        <DatePicker
                            selected={validFrom}
                            onChange={setValidFrom}
                            showPopperArrow={false}
                            calendarStartDay={1}
                            todayButton="Vandaag"
                            showWeekNumbers
                            dateFormat="d-M-yyyy"
                            className={"form-control form-control-lg" + (validFromIsError ? " form-input-error" : "")}
                            renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => <DatePickerHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />}
                        />
                    </div>
                    <Form.Control type="hidden" isInvalid={!!errors.validFrom} />
                    <Form.Control.Feedback type="invalid">{errors.validFrom?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Basisbezetting</Form.Label>
                    <InfoPopover id="occupationTemplate" body="Kies hier welke basisbezetting gebruikt moet worden voor dit basisrooster." />
                    <Form.Select {...register("occupationTemplate")} isInvalid={!!errors.occupationTemplate?.message}>
                        <option value="">Kies...</option>
                        {occupationTemplates?.map(({ id, name }) => (
                            <option key={id} value={`${id}`}>
                                {name}
                            </option>
                        ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.occupationTemplate?.message}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Weekcyclus</Form.Label>
                    <InfoPopover
                        id="weekCycle"
                        body="Indien er met even en oneven weken gewerkt wordt, kies je hier voor 2. Dit komt bijvoorbeeld voor als er medewerkers zijn die om de week een dag vrij zijn."
                    />
                    <Form.Control type="number" {...register("weekCycle")} isInvalid={!!errors.weekCycle?.message} />
                    <Form.Control.Feedback type="invalid">{errors.weekCycle?.message}</Form.Control.Feedback>
                </Form.Group>
                {mode === "Create" ? (
                    <div className="row">
                        <Form.Group className={`mb-3 ${initializationMode === ShiftTemplateInitMode.COPY ? "col-6" : ""}`}>
                            <Form.Label>Invulling</Form.Label>
                            <InfoPopover
                                id="initializationMode"
                                body="Kies de basis voor het nieuwe rooster. Een leeg rooster / gebruik een bestaand rooster als basis / genereer rooster automatisch op basis van medewerkers, werktijden, toegewezen taken en de basisbezetting."
                            />
                            <Form.Select {...register("initializationMode")} isInvalid={!!errors.initializationMode}>
                                {initializationModeOptions.map(({ id, name }) => (
                                    <option key={id} value={id}>
                                        {name}
                                    </option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">{errors.initializationMode?.message}</Form.Control.Feedback>
                        </Form.Group>
                        {initializationMode === ShiftTemplateInitMode.COPY ? (
                            <Form.Group className="mb-3 col-6">
                                <Form.Label>Kopieer basisrooster</Form.Label>
                                <Form.Select {...register("sourceTemplate")} isInvalid={!!errors.sourceTemplate}>
                                    <option value="">Kies...</option>
                                    {shiftTemplates?.map(({ id, name }) => (
                                        <option key={id} value={`${id}`}>
                                            {name}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">{errors.sourceTemplate?.message}</Form.Control.Feedback>
                            </Form.Group>
                        ) : null}
                    </div>
                ) : null}
                {initializationMode === ShiftTemplateInitMode.GENERATE ? (
                    <div className="row">
                        <Form.Group className="mb-3 col-6">
                            <Form.Label>Genereer voor medewerkers</Form.Label>
                            <div className="container-scroll" style={{ maxHeight: "150px" }}>
                                <InlineFormMultiSelect id="users" options={userOptions} values={users} onChange={setUsers} toggleAllEnabled={true} />
                            </div>
                            <Form.Control type="hidden" isInvalid={!!errors.users?.message} />
                            <Form.Control.Feedback type="invalid">{errors.users?.message}</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3 col-6">
                            <Form.Label>Genereer taken</Form.Label>
                            <div className="container-scroll" style={{ maxHeight: "150px" }}>
                                <InlineFormMultiSelect id="roles" options={roleOptions} values={roles} onChange={setRoles} toggleAllEnabled={true} />
                            </div>
                            <Form.Control type="hidden" isInvalid={!!errors.roles?.message} />
                            <Form.Control.Feedback type="invalid">{errors.roles?.message}</Form.Control.Feedback>
                        </Form.Group>
                    </div>
                ) : null}
                {mode === "Create" ? (
                    <Button type="submit" data-cy="createShiftTemplate">
                        Maak
                    </Button>
                ) : null}
                {mode === "Update" ? (
                    <>
                        <Button type="submit" data-cy="saveShiftTemplate">
                            Opslaan
                        </Button>
                        <Button type="button" variant="danger" onClick={onDeleteShiftTemplate} className="ms-2">
                            Verwijder
                        </Button>
                    </>
                ) : null}
                {deletionWarningVisible ? (
                    <span className="ms-3">
                        <FontAwesomeIcon icon={faExclamationCircle} className="me-1" /> Weet je zeker dat je dit basisrooster wil verwijderen?{" "}
                        <Button type="button" variant="link" onClick={onDeleteShiftTemplate}>
                            Ja, verwijder
                        </Button>
                    </span>
                ) : null}
                <Form.Group>
                    <Form.Control type="hidden" isInvalid={!!errors.root} />
                    <Form.Control.Feedback type="invalid" data-cy="root_errors">
                        {errors.root?.message}
                    </Form.Control.Feedback>
                </Form.Group>
            </Form>
        </RegularPage>
    )
}

export default EditShiftTemplateDetailsPage
