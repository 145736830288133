import { faSliders } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useCallback, useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import DateSelector, { DateLabelType } from "../../components/DateSelector"
import StaticDaySchedule from "../../components/dashboard/staticDay/StaticDaySchedule"
import StaticWeekSchedule from "../../components/dashboard/staticWeek/StaticWeekSchedule"
import DateNotes from "../../components/dateNotes/DateNotes"
import DateControl from "../../components/headlineControls/DateControl"
import NumberOfWeeksSelector from "../../components/headlineControls/NumberOfWeeksSelector"
import { useTabs } from "../../contexts/TabsContext"
import { useCurrentUser, useIsAdmin } from "../../contexts/UserSettingsContext"
import { loadNotesForWeek } from "../../helpers/DateNoteHelper"
import { addDays, getDayId, getFirstMondayOfWeek, getFirstNextWorkday, getGreeting, getWeekNumber, prettyPrint, prettyPrintShort } from "../../helpers/DaysHelper"
import { ScheduleMap, getScheduleMap } from "../../helpers/ScheduleMapHelper"
import { getPersonalShiftsAndLocalities } from "../../services/Schedule"
import AbsenceRequestsView from "./AbsenceRequestsView"
import styles from "./Dashboard.module.scss"

const Dashboard = () => {
    const navigate = useNavigate()
    const { setActiveTab } = useTabs()
    const currentUser = useCurrentUser()
    const isAdmin = useIsAdmin()

    const today = new Date()
    const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate())
    const startOfFirstNextWorkday = getFirstNextWorkday(startOfToday, currentUser.enabledDays)
    const startOfCurrentWeek = getFirstMondayOfWeek(startOfFirstNextWorkday)

    const [weekViewStartOfWeek, setWeekViewStartOfWeek] = useState(startOfCurrentWeek)
    const [weekViewSelectedDate, setWeekViewSelectedDate] = useState(startOfFirstNextWorkday)
    const [weekScheduleMap, setWeekScheduleMap] = useState<ScheduleMap>(new Map())

    const [dayViewStartOfWeek, setDayViewStartOfWeek] = useState(startOfCurrentWeek)
    const [dayViewSelectedDate, setDayViewSelectedDate] = useState(startOfFirstNextWorkday)
    const [dayScheduleMap, setDayScheduleMap] = useState<ScheduleMap>(new Map())

    const [numberOfWeeks, setNumberOfWeeks] = useState<number>(1)

    const { data: notes } = useQuery(["dateNotes", currentUser.id, dayViewStartOfWeek], () => loadNotesForWeek(currentUser.locations, currentUser.id, dayViewStartOfWeek), {
        initialData: new Map(),
    })

    const loadDayShifts = () => {
        getPersonalShiftsAndLocalities(currentUser.locations, currentUser.id, dayViewStartOfWeek.getTime(), 7).then((response) => {
            const { shifts, shiftMutations, localities, localityMutations } = response.data
            setDayScheduleMap(getScheduleMap(shifts, shiftMutations, localities, localityMutations))
        })
    }

    const loadWeekShifts = () => {
        getPersonalShiftsAndLocalities(currentUser.locations, currentUser.id, weekViewStartOfWeek.getTime(), numberOfWeeks * 7).then((response) => {
            const { shifts, shiftMutations, localities, localityMutations } = response.data
            setWeekScheduleMap(getScheduleMap(shifts, shiftMutations, localities, localityMutations))
        })
    }

    const updateDayViewStartOfWeek = () => {
        const newStartOfWeek = getFirstMondayOfWeek(dayViewSelectedDate)
        if (newStartOfWeek.getTime() !== dayViewStartOfWeek.getTime()) {
            setDayViewStartOfWeek(newStartOfWeek)
        }
    }

    const updateWeekViewStartOfWeek = () => {
        const newStartOfWeek = getFirstMondayOfWeek(weekViewSelectedDate)
        if (newStartOfWeek.getTime() !== weekViewStartOfWeek.getTime()) {
            setWeekViewStartOfWeek(newStartOfWeek)
        }
    }

    const navigateToWeekPlanning = useCallback(() => {
        navigate("/weekplanning")
    }, [navigate])

    useEffect(() => setActiveTab("Dashboard"), [setActiveTab])
    useEffect(loadDayShifts, [currentUser, dayViewStartOfWeek])
    useEffect(updateDayViewStartOfWeek, [dayViewSelectedDate, setDayViewStartOfWeek])
    useEffect(updateWeekViewStartOfWeek, [weekViewSelectedDate, setWeekViewStartOfWeek])
    useEffect(loadWeekShifts, [currentUser, weekViewStartOfWeek, numberOfWeeks])

    return (
        <div className="w-100">
            <div className="row">
                <div className="col-12">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className={styles.headline}>
                                <span data-cy="greeting" className="h4 mb-0">
                                    {getGreeting(new Date())} {currentUser.firstName}!
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 col-md-5 col-xl-4">
                    <div className="card mb-4" data-cy="dayView" id="dayView">
                        <div className={"card-body " + styles.dashboardCard}>
                            <div className="mb-2">
                                <div className={styles.dateContainer}>
                                    <div data-cy="selectedDate" className={"h4 d-none d-sm-block " + styles.dateTitle}>
                                        {prettyPrint(dayViewSelectedDate).charAt(0).toUpperCase() + prettyPrint(dayViewSelectedDate).slice(1)}
                                    </div>
                                    <div className={"h4 d-block d-sm-none " + styles.dateTitle}>
                                        {prettyPrintShort(dayViewSelectedDate).charAt(0).toUpperCase() + prettyPrintShort(dayViewSelectedDate).slice(1)}
                                    </div>
                                    <div className="selectable-segment">
                                        <DateSelector selectedDate={dayViewSelectedDate} setSelectedDate={setDayViewSelectedDate} labelType={DateLabelType.NONE} />
                                        <DateControl mode="PREVIOUS" unit="DAY" selectedDate={dayViewSelectedDate} setSelectedDate={setDayViewSelectedDate} enabledDays={currentUser.enabledDays} />
                                        <DateControl mode="NEXT" unit="DAY" selectedDate={dayViewSelectedDate} setSelectedDate={setDayViewSelectedDate} enabledDays={currentUser.enabledDays} />
                                    </div>
                                </div>
                            </div>
                            <DateNotes notes={notes?.get(getDayId(dayViewSelectedDate))} />
                            <div className="mt-4">
                                <StaticDaySchedule date={dayViewSelectedDate} scheduleMap={dayScheduleMap} />
                            </div>
                        </div>
                    </div>
                    <div className="card mb-4" id="absenceRequestsView">
                        <div className={"card-body " + styles.dashboardCard}>
                            <div className="h4 mb-4">Verlofaanvragen</div>
                            <table className={styles.absenceRequestsTable}>
                                <tbody>
                                    <tr>
                                        <td className="text-bold">Van</td>
                                        <td className="text-bold">Tot</td>
                                        <td className="text-bold">Beoordeling</td>
                                        <td className="text-bold">Afhandeling</td>
                                    </tr>
                                    <AbsenceRequestsView startOfToday={startOfToday} userId={currentUser.id} />
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-7 col-xl-8">
                    <div className="card" data-cy="weekView" id="weekView">
                        <div className={"card-body " + styles.dashboardCard}>
                            <div className="mb-4">
                                <div className={styles.dateContainer}>
                                    <div data-cy="selectedDate" className={`h4 ${styles.dateTitle}`}>
                                        Week {getWeekNumber(weekViewStartOfWeek)}
                                        {numberOfWeeks > 1 ? `-${getWeekNumber(addDays(weekViewStartOfWeek, (numberOfWeeks - 1) * 7))}` : ""}
                                    </div>
                                    <div className="d-flex gap-1 align-items-center">
                                        {isAdmin ? (
                                            <Button variant="link" onClick={navigateToWeekPlanning}>
                                                <FontAwesomeIcon icon={faSliders} />
                                            </Button>
                                        ) : null}
                                        <div className="selectable-segment">
                                            <div className="me-1">
                                                <NumberOfWeeksSelector setNumberOfWeeks={setNumberOfWeeks} />
                                            </div>
                                            <DateSelector selectedDate={weekViewSelectedDate} setSelectedDate={setWeekViewSelectedDate} labelType={DateLabelType.NONE} />
                                            <DateControl
                                                mode="PREVIOUS"
                                                unit="WEEK"
                                                selectedDate={weekViewSelectedDate}
                                                setSelectedDate={setWeekViewSelectedDate}
                                                enabledDays={currentUser.enabledDays}
                                            />
                                            <DateControl mode="NEXT" unit="WEEK" selectedDate={weekViewSelectedDate} setSelectedDate={setWeekViewSelectedDate} enabledDays={currentUser.enabledDays} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <StaticWeekSchedule fromDate={weekViewStartOfWeek} numberOfWeeks={numberOfWeeks} scheduleMap={weekScheduleMap} showLocation={true} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
