import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import { getDayId } from "../helpers/DaysHelper"
import { getEnabledRoles } from "../helpers/FilterHelper"
import { OccupationDetails, OccupationMap } from "../helpers/OccupationHelper"
import { NestedRoleItem, createNestedRoles, loadRolesAndGroupsForLocation } from "../helpers/RolesHelper"
import { ScheduleItem, ScheduleMap, StaticShift, hasScheduleItem, mergeLocalityMutation, mergeMaps, mergeShiftMutation } from "../helpers/ScheduleMapHelper"
import { LoadedSchedule } from "../helpers/ShiftHelper"
import { loadUsersForDate } from "../helpers/UsersHelper"
import { NotesType } from "../types/DateNoteType"
import HiringAvailabilityType, { HiringAvailabilityStatusWithUnknown } from "../types/HiringAvailabilityType"
import { LocalityOptionOrInherit } from "../types/LocalityType"
import LocationType from "../types/LocationType"
import RoleGroupType from "../types/RoleGroupType"
import RoleType from "../types/RoleType"
import ShiftTemplateType from "../types/ShiftTemplateType"
import { UserType } from "../types/UserType"
import { ValidationMessageType } from "../types/ValidationMessageType"
import { useLocations } from "./UserSettingsContext"

export interface ScheduleEditorState {
    revisionId: number
    location: LocationType
    shiftTemplate: ShiftTemplateType | null
    dayId: string
    date: Date | null
    day: number | null
    errorMessage: string | null
    mergedSchedule: ScheduleMap
    mergedScheduleMutations: ScheduleMap
    newScheduleMutations: ScheduleMap
    hasNewShiftMutations: boolean
    hasNewLocalityMutations: boolean
    roles: RoleType[]
    roleGroups: RoleGroupType[]
    nestedRoles: NestedRoleItem[]
    users: UserType[]
    occupations: OccupationMap
    enabledRoles: number[]
    submittedRoleFilterValues: string[]
    submittedHiringAvailabilities: HiringAvailabilityStatusWithUnknown[]
    validationMessages: ValidationMessageType[]
    notes: NotesType
    hiringAvailabilities: HiringAvailabilityType[]
}

interface ScheduleEditorContextProps {
    loading: boolean
    mode: "DAYS" | "DATERANGE"
    editorState?: ScheduleEditorState
    updateDate: (newDate: Date) => void
    updateDay: (newDay: number) => void
    updateLocation: (newLocation: LocationType) => void
    updateShiftTemplate: (newShiftTemplate: ShiftTemplateType) => void
    applyMutation: (user: number, cellFrom: number, cellTo: number, role: number | null) => void
    applyMutations: (mutations: ScheduleMap) => void
    setLocality: (user: number, locality: LocalityOptionOrInherit) => void
    setRoleFilter: (selectedRoleFilterValues: string[]) => void
    setHiringAvailabilityFilter: (selectedHiringAvailabilityFilterValues: HiringAvailabilityStatusWithUnknown[]) => void
    reloadNotes: () => void
    reloadEditorFromSchedule: () => void
    reloadEditorFromShiftTemplate: () => void
}

const contextValue: ScheduleEditorContextProps = {
    loading: false,
    mode: "DAYS",
    updateDate: () => {},
    updateDay: () => {},
    updateLocation: () => {},
    updateShiftTemplate: () => {},
    applyMutation: () => {},
    applyMutations: () => {},
    setLocality: () => {},
    setRoleFilter: () => {},
    setHiringAvailabilityFilter: () => {},
    reloadNotes: () => {},
    reloadEditorFromSchedule: () => {},
    reloadEditorFromShiftTemplate: () => {},
}

export const ScheduleEditorContext = createContext<ScheduleEditorContextProps>(contextValue)

interface ScheduleEditorContextProviderProps {
    mode: "DAYS" | "DATERANGE"
    children: ReactNode
    initialLocation: LocationType
    initialDate?: Date
    initialDay?: number
    loadShiftTemplate?: () => Promise<ShiftTemplateType>
    loadSchedule: (shiftTemplate: ShiftTemplateType | null, location: LocationType | null, date: Date | null) => Promise<LoadedSchedule>
    loadOccupations: (shiftTemplate: ShiftTemplateType | null, location: LocationType | null, date: Date | null) => Promise<any>
    loadNotes?: (location: LocationType, date: Date) => Promise<NotesType>
    loadHiringAvailabilities?: (location: LocationType, date: Date) => Promise<HiringAvailabilityType[]>
}

export const ScheduleEditorContextProvider = ({
    mode,
    children,
    initialLocation,
    initialDate,
    initialDay,
    loadShiftTemplate,
    loadSchedule,
    loadOccupations,
    loadNotes,
    loadHiringAvailabilities,
}: ScheduleEditorContextProviderProps) => {
    const locations = useLocations()

    const [lastUsedRevisionId, setLastUsedRevisionId] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(true)
    const [location, setLocation] = useState<LocationType>(initialLocation)
    const [shiftTemplate, setShiftTemplate] = useState<ShiftTemplateType | null>(null)
    const [date, setDate] = useState<Date | null>(initialDate ?? null)
    const [day, setDay] = useState<number | null>(initialDay ?? null)
    const [users, setUsers] = useState<UserType[] | null>(null)
    const [roles, setRoles] = useState<RoleType[] | null>(null)
    const [roleGroups, setRoleGroups] = useState<RoleGroupType[] | null>(null)
    const [nestedRoles, setNestedRoles] = useState<NestedRoleItem[] | null>(null)
    const [mergedSchedule, setMergedSchedule] = useState<ScheduleMap | null>(null) // Schedule map with original schedule, mutations, newMutations merged
    const [mergedScheduleMutations, setMergedScheduleMutations] = useState<ScheduleMap>(new Map()) // Schedule map with saved and unsaved mutations merged
    const [schedule, setSchedule] = useState<ScheduleMap>(new Map()) // Schedule map with original schedule
    const [scheduleMutations, setScheduleMutations] = useState<ScheduleMap>(new Map()) // Schedule map with saved mutations
    const [newScheduleMutations, setNewScheduleMutations] = useState<ScheduleMap>(new Map()) // Schedule map with unsaved mutations
    const [validationMessages, setValidationMessages] = useState<ValidationMessageType[]>([])
    const [occupations, setOccupations] = useState<OccupationDetails | null>(null)
    const [submittedRoleFilterValues, setSubmittedRoleFilterValues] = useState<string[]>([])
    const [submittedHiringAvailabilities, setSubmittedHiringAvailabilities] = useState<HiringAvailabilityStatusWithUnknown[]>([])
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const {
        data: notes,
        isLoading: isLoadingNotes,
        refetch: reloadNotes,
    } = useQuery(["notes", location, date], () => (loadNotes ? loadNotes(location, date!) : Promise.resolve({ dateNotes: [], holidayNotes: [] })))
    const { data: hiringAvailabilities, isLoading: isLoadingHiringAvailabilities } = useQuery(["hiringAvailabilities", location, date], () =>
        loadHiringAvailabilities ? loadHiringAvailabilities(location, date!) : Promise.resolve([])
    )

    const dayId = useMemo<string>(() => {
        return date ? getDayId(date) : day!.toString()
    }, [date, day])

    const hasNewShiftMutations = useMemo(() => {
        return hasScheduleItem(newScheduleMutations, (item: ScheduleItem) => item.shifts.length > -1)
    }, [newScheduleMutations])

    const hasNewLocalityMutations = useMemo(() => {
        return hasScheduleItem(newScheduleMutations, (item: ScheduleItem) => item.locality !== undefined)
    }, [newScheduleMutations])

    const enabledRoles = useMemo(() => {
        return getEnabledRoles(submittedRoleFilterValues)
    }, [submittedRoleFilterValues])

    const [editorState, setEditorState] = useState<ScheduleEditorState>()

    // ----- Reload actions -----

    const reloadUsers = useCallback(
        (location: LocationType, date: Date | null) => {
            setUsers(null)
            setLoading(true)
            loadUsersForDate(location, date).then((users) => setUsers(users))
        },
        [setUsers, setLoading]
    )

    const reloadRoles = useCallback(
        (location: LocationType) => {
            setNestedRoles(null)
            setLoading(true)
            loadRolesAndGroupsForLocation(location).then((response) => {
                const { roles, roleGroups } = response
                const nestedRoles = createNestedRoles(roles, roleGroups)
                setRoles(roles)
                setRoleGroups(roleGroups)
                setNestedRoles(nestedRoles)
            })
        },
        [setNestedRoles, setLoading, setRoles, setRoleGroups, setNestedRoles]
    )

    const reloadSchedule = useCallback(
        (shiftTemplate: ShiftTemplateType | null, location: LocationType | null, date: Date | null, resetNewScheduleMutations: boolean) => {
            setMergedSchedule(null)
            setLoading(true)
            loadSchedule(shiftTemplate, location, date).then((response) => {
                setSchedule(response.schedule)
                setScheduleMutations(response.scheduleMutations)
                setValidationMessages(response.validationMessages)
                if (resetNewScheduleMutations) {
                    setNewScheduleMutations(new Map())
                }
                setErrorMessage(response.errorMessage ?? null)
            })
        },
        [setMergedSchedule, setLoading, loadSchedule, setSchedule, setScheduleMutations, setValidationMessages, setNewScheduleMutations]
    )

    const reloadScheduleIfNeeded = useCallback(
        (shiftTemplate: ShiftTemplateType | null, location: LocationType | null, date: Date | null, resetNewScheduleMutations: boolean) => {
            if (mergedSchedule && mergedSchedule.has(dayId)) {
                return
            }
            reloadSchedule(shiftTemplate, location, date, resetNewScheduleMutations)
        },
        [mergedSchedule, shiftTemplate, location, date]
    )

    const reloadOccupations = useCallback(
        (shiftTemplate: ShiftTemplateType | null, location: LocationType | null, date: Date | null) => {
            setOccupations(null)
            setLoading(true)
            loadOccupations(shiftTemplate, location, date).then((response) => setOccupations(response))
        },
        [setOccupations, setLoading, loadOccupations, setOccupations]
    )

    // ----- Initialization: -----

    useEffect(() => {
        if (loadShiftTemplate) {
            loadShiftTemplate().then((response) => updateShiftTemplate(response))
        } else {
            reloadUsers(initialLocation, initialDate!)
            reloadRoles(initialLocation)
            reloadSchedule(null, initialLocation, initialDate!, false)
            reloadOccupations(null, initialLocation, initialDate!)
        }
    }, [loadShiftTemplate, reloadUsers, reloadRoles, reloadSchedule, initialLocation, initialDate])

    const reloadEditorFromSchedule = useCallback(() => {
        reloadSchedule(null, location, date, true)
        reloadOccupations(null, location, date)
    }, [reloadSchedule, reloadOccupations, setNewScheduleMutations, location, date])

    const reloadEditorFromShiftTemplate = useCallback(() => {
        reloadSchedule(shiftTemplate, null, null, true)
        reloadOccupations(shiftTemplate, null, null)
    }, [reloadSchedule, reloadOccupations, setNewScheduleMutations, shiftTemplate])

    // ----- External changes: -----

    const updateLocation = useCallback(
        (newLocation: LocationType) => {
            if (newLocation.id === location.id) {
                return
            }
            reloadUsers(newLocation, date)
            reloadRoles(newLocation)
            reloadSchedule(null, newLocation, date, false)
            reloadOccupations(null, newLocation, date)
            setLocation(newLocation)
        },
        [reloadUsers, reloadRoles, reloadSchedule, reloadOccupations, setLocation, location, date]
    )

    const updateShiftTemplate = useCallback(
        (newShiftTemplate: ShiftTemplateType) => {
            if (newShiftTemplate === shiftTemplate) {
                return
            }
            const newLocation = locations.find((l) => l.id === newShiftTemplate.location)!
            const validFrom = new Date(newShiftTemplate.validFrom)
            reloadUsers(newLocation, validFrom)
            reloadRoles(newLocation)
            reloadSchedule(newShiftTemplate, null, null, true)
            reloadOccupations(newShiftTemplate, null, null)
            setLocation(newLocation)
            setShiftTemplate(newShiftTemplate)
        },
        [reloadUsers, reloadRoles, reloadSchedule, reloadOccupations, setLocation, setShiftTemplate, shiftTemplate, locations]
    )

    const updateDate = useCallback(
        (newDate: Date) => {
            if (newDate === date) {
                return
            }
            reloadUsers(location, newDate)
            reloadScheduleIfNeeded(null, location, newDate, false)
            reloadOccupations(null, location, newDate)
            setDate(newDate)
        },
        [reloadUsers, reloadScheduleIfNeeded, reloadOccupations, setDate, location, date]
    )

    const updateDay = useCallback((newDay: number) => setDay(newDay), [setDay])

    // ----- Actions -----

    const applyMutation = useCallback(
        (user: number, cellFrom: number, cellTo: number, role: number | null) => {
            const newMutation: StaticShift = {
                startSlot: location.startSlot + cellFrom,
                endSlot: location.startSlot + cellTo,
                role: role,
            }
            setNewScheduleMutations(mergeShiftMutation(newScheduleMutations, newMutation, location.id.toString(), dayId, user.toString()))
        },
        [location, setNewScheduleMutations, newScheduleMutations, dayId]
    )

    const applyMutations = useCallback(
        (scheduleMutations: ScheduleMap) => {
            setNewScheduleMutations(mergeMaps(newScheduleMutations, scheduleMutations, false))
        },
        [setNewScheduleMutations, newScheduleMutations]
    )

    const setLocality = useCallback(
        (user: number, locality: LocalityOptionOrInherit) => {
            setNewScheduleMutations(mergeLocalityMutation(newScheduleMutations, locality, location.id.toString(), dayId, user.toString()))
        },
        [setNewScheduleMutations, newScheduleMutations, location, dayId]
    )

    const setRoleFilter = useCallback(
        (roles: string[]) => {
            setSubmittedRoleFilterValues(roles)
        },
        [setSubmittedRoleFilterValues]
    )

    const setHiringAvailabilityFilter = useCallback(
        (availabilities: HiringAvailabilityStatusWithUnknown[]) => {
            setSubmittedHiringAvailabilities(availabilities)
        },
        [setSubmittedHiringAvailabilities]
    )

    // ----- Side Effects -----

    useEffect(() => {
        setLastUsedRevisionId(editorState?.revisionId ?? 0)
    }, [editorState, setLastUsedRevisionId])

    useEffect(() => {
        setMergedScheduleMutations(mergeMaps(scheduleMutations, newScheduleMutations, false))
    }, [setMergedScheduleMutations, scheduleMutations, newScheduleMutations])

    useEffect(() => {
        setMergedSchedule(mergeMaps(schedule, mergedScheduleMutations, true))
    }, [setMergedSchedule, schedule, mergedScheduleMutations])

    useEffect(() => {
        if (!mergedSchedule || !roles || !roleGroups || !nestedRoles || !users || !enabledRoles || !occupations || !validationMessages || isLoadingNotes || isLoadingHiringAvailabilities) {
            return
        }

        setLoading(false)
        const occupationTemplateDayId = date ? dayId : (day! % (occupations.weekCycle * 7)).toString()
        const occupationMap: OccupationMap = occupations.map.has(occupationTemplateDayId) ? occupations.map.get(occupationTemplateDayId)! : new Map()

        setEditorState({
            revisionId: lastUsedRevisionId + 1,
            location,
            shiftTemplate,
            dayId,
            date,
            day,
            errorMessage: errorMessage ?? null,
            mergedSchedule,
            mergedScheduleMutations,
            roles,
            roleGroups,
            nestedRoles,
            users,
            newScheduleMutations,
            hasNewShiftMutations,
            hasNewLocalityMutations,
            occupations: occupationMap,
            enabledRoles,
            submittedRoleFilterValues,
            submittedHiringAvailabilities,
            validationMessages,
            notes: notes!,
            hiringAvailabilities: hiringAvailabilities!,
        })
    }, [mergedSchedule, nestedRoles, users, enabledRoles, submittedHiringAvailabilities, occupations, validationMessages, notes, dayId, hiringAvailabilities, errorMessage])

    return (
        <ScheduleEditorContext.Provider
            value={{
                loading,
                mode,
                editorState,
                updateDate,
                updateDay,
                updateLocation,
                updateShiftTemplate,
                applyMutation,
                applyMutations,
                setLocality,
                setRoleFilter,
                setHiringAvailabilityFilter,
                reloadNotes,
                reloadEditorFromSchedule,
                reloadEditorFromShiftTemplate,
            }}
        >
            {children}
        </ScheduleEditorContext.Provider>
    )
}

export const ScheduleEditorContextConsumer = ScheduleEditorContext.Consumer

export function useScheduleEditorState() {
    const { editorState } = useContext(ScheduleEditorContext)
    return editorState!
}
